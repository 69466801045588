<!--
 * @Description: 驳回项目报价
-->
<template>
  <a-modal v-model="show" title="驳回项目报价" @ok="handleConfirm" :confirmLoading="loading">
    <div class="main_content">
      <a-form-model :model="form" ref="form" :rules="rules" v-bind="DEFAULT_FORM_LAYOUT">
        <a-form-model-item label="驳回原因" prop="rejectReason">
          <a-textarea :rows="4" v-model="form.rejectReason"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import DEFAULT_FORM_LAYOUT from '@/config/formLayout'
import ApiController from '@/views/project/api'
import { NotEmptyRule } from '@/utils/rules'

export default {
  data() {
    return {
      DEFAULT_FORM_LAYOUT,
      show: false,
      loading: false,
      form: {
        rejectReason: ''
      },
      rules: {
        rejectReason: [NotEmptyRule]
      }
    }
  },
  methods: {
    handleShow(row) {
      this.item = row
      this.form.rejectReason = ''
      this.show = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    async handleConfirm() {
      const params = {
        id: this.item.id,
        confirm: false,
        rejectReason: this.form.rejectReason
      }

      try {
        this.loading = true
        await this.$refs.form.validate()
        console.log(params)
        await ApiController.confirmOffer(params)
        this.$emit('successed')
        this.$message.success('驳回报价成功!')
        this.show = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
