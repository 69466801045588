var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "table_area_wrapper" },
    [
      _c(
        "div",
        { staticClass: "mb-10 btn_group_wrapper" },
        [
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "auth",
                  rawName: "v-auth:ORGANIZATION_PROJECTS_ADD",
                  arg: "ORGANIZATION_PROJECTS_ADD",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.handleAddEditItem },
            },
            [_vm._v("新增项目")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "custom_vxe_table_wrapper" },
        [
          _c(
            "vxe-table",
            {
              attrs: {
                height: "auto",
                data: _vm.tableData,
                loading: _vm.loading,
              },
            },
            [
              _c("vxe-column", {
                attrs: {
                  title: "项目ID",
                  field: "projectCode",
                  width: "180px",
                },
              }),
              _c("vxe-column", {
                attrs: { title: "项目名称", field: "name", width: "160px" },
              }),
              _c("vxe-column", {
                attrs: { title: "项目地址", field: "address", width: "360px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(row.districtAddress) + _vm._s(row.address)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-column", {
                attrs: {
                  title: "项目描述",
                  field: "description",
                  width: "360px",
                },
              }),
              _c("vxe-column", {
                attrs: {
                  title: "楼栋数量",
                  field: "buildingCount",
                  width: "120px",
                  align: "center",
                },
              }),
              _c("vxe-column", {
                attrs: {
                  title: "雷达检测点数量",
                  field: "radarCount",
                  width: "140px",
                  align: "center",
                },
              }),
              _c("vxe-column", {
                attrs: {
                  title: "项目报价",
                  field: "offer",
                  width: "160px",
                  align: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "offer_wrapper" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("transformBidValue")(row.offer))
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-column", {
                attrs: { title: "状态", field: "statusName", width: "120px" },
              }),
              _c("vxe-column", {
                attrs: {
                  title: "创建时间",
                  field: "createTime",
                  width: "160px",
                },
              }),
              _c("vxe-column", {
                attrs: {
                  title: "操作",
                  align: "right",
                  field: "operate",
                  "min-width": "240px",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "a-button",
                          {
                            directives: [
                              {
                                name: "auth",
                                rawName: "v-auth:EDIT_PROJECTS",
                                arg: "EDIT_PROJECTS",
                              },
                            ],
                            attrs: { type: "link" },
                            on: {
                              click: function () {
                                return _vm.handleAddEditItem(row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _vm.showLauchBtns(row)
                          ? [
                              _c(
                                "a-button",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName:
                                        "v-auth:ORGANIZATION_PROJECTS_DEL",
                                      arg: "ORGANIZATION_PROJECTS_DEL",
                                    },
                                  ],
                                  attrs: { type: "link" },
                                  on: {
                                    click: function () {
                                      return _vm.handleDeleteItem(row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                              _c(
                                "a-button",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName:
                                        "v-auth:ORGANIZATION_PROJECTS_REQUEST_QUOTATION",
                                      arg: "ORGANIZATION_PROJECTS_REQUEST_QUOTATION",
                                    },
                                  ],
                                  attrs: { type: "link" },
                                  on: {
                                    click: function () {
                                      return _vm.handleSubmitBidRequest(row)
                                    },
                                  },
                                },
                                [_vm._v("请求报价")]
                              ),
                            ]
                          : _vm._e(),
                        _vm.showConfirmBidBtns(row)
                          ? [
                              _c(
                                "a-button",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName:
                                        "v-auth:ORGANIZATION_PROJECTS_CONFIRM_QUOTATION",
                                      arg: "ORGANIZATION_PROJECTS_CONFIRM_QUOTATION",
                                    },
                                  ],
                                  attrs: { type: "link" },
                                  on: {
                                    click: function () {
                                      return _vm.handleAceptBid(row)
                                    },
                                  },
                                },
                                [_vm._v("确认报价")]
                              ),
                              _c(
                                "a-button",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName:
                                        "v-auth:ORGANIZATION_PROJECTS_REJECT_OFFER",
                                      arg: "ORGANIZATION_PROJECTS_REJECT_OFFER",
                                    },
                                  ],
                                  attrs: { type: "link" },
                                  on: {
                                    click: function () {
                                      return _vm.handleRejectBid(row)
                                    },
                                  },
                                },
                                [_vm._v("驳回报价")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Pagination", {
        attrs: { pagination: _vm.pagination },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
          change: _vm.fetchData,
        },
      }),
      _c("RejectBidDialog", {
        ref: "RejectBidDialog",
        on: { successed: _vm.fetchData },
      }),
      _c("AddEditItemDialog", {
        ref: "AddEditItemDialog",
        attrs: { item: _vm.currentRow },
        on: { successed: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }