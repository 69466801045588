var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "驳回原因" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-spin",
        { staticClass: "main_content", attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "form",
                staticClass: "reject_reason_form",
                attrs: { model: _vm.form },
              },
              "a-form-model",
              _vm.formLayout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { prop: "reason", label: "驳回原因" } },
                [_c("span", [_vm._v(_vm._s(_vm.form.reason))])]
              ),
              _c(
                "a-form-model-item",
                { attrs: { prop: "operationUserName", label: "处理人" } },
                [_c("span", [_vm._v(_vm._s(_vm.form.operationUserName))])]
              ),
              _c(
                "a-form-model-item",
                { attrs: { prop: "startAndEnd", label: "处理时间" } },
                [_c("span", [_vm._v(_vm._s(_vm.form.createTime))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }