<!--
 * @Description: 筛选表单
-->
<template>
  <a-card class="form_wrapper">
    <a-form-model :model="form" ref="form" v-bind="formLayout">
      <FilterLayout @reset="reset" @search="search">
        <a-form-model-item prop="projectCode" label="项目Id">
          <a-input v-model="form.projectCode" placeholder="请输入项目Id"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="name" label="项目名称">
          <a-input v-model="form.name" placeholder="请输入项目名称"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="status" label="状态">
          <a-select allowClear v-model="form.status" placeholder="请选择状态">
            <a-select-option
              v-for="val in PROJECT_STATUS_ENUM.values"
              :key="val"
              :value="val"
            >{{ PROJECT_STATUS_ENUM[val] }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="startAndEnd" label="创建时间">
          <a-range-picker v-model="form.startAndEnd"></a-range-picker>
        </a-form-model-item>
      </FilterLayout>
    </a-form-model>
  </a-card>
</template>

<script>
// import PROJECT_STATUS_ENUM from '@/const/ProjectStatus'
const PROJECT_STATUS_ENUM = {
  LAUNCHING: '立项中', //
  'OFFERING,REJECTED': '报价中', //
  OFFERED: '报价待确认',
  REJECTED: '报价中',
  'COMPLETE,ANALYSED': '报价完成',
  values: ['LAUNCHING', 'OFFERING,REJECTED', 'OFFERED', 'COMPLETE,ANALYSED']
}

const FORM = () => ({
  status: undefined,
  organizationName: '',
  phone: '',
  contacter: '',
  startAndEnd: [undefined, undefined],
  projectCode: '',
  name: ''
})

export default {
  name: '',
  data() {
    return {
      PROJECT_STATUS_ENUM,
      formLayout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
      },
      form: FORM()
    }
  },
  mounted() {},
  methods: {
    reset() {
      this.form = FORM()
      this.$nextTick(() => {
        this.search()
      })
    },
    search() {
      this.$emit('search')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
