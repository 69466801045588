<!--
 * @descriptionription: 编辑
-->
<template>
  <a-modal
    v-model="show"
    title="新增修改项目"
    class="form_wrapper"
    width="700px"
    @ok="handleConfirm"
    :confirmLoading="loading"
  >
    <a-form-model :model="form" :rules="rules" ref="form" v-bind="formLayout">
      <a-form-model-item prop="name" label="项目名称">
        <a-input v-model="form.name" :disabled="diableBaseInfo"></a-input>
      </a-form-model-item>

      <a-form-model-item prop="address" label="地址">
        <DisctrictSelector
          placeholder="请选择省市区"
          :maxLevel="3"
          :allowClear="false"
          v-model="form.districtCode"
        ></DisctrictSelector>
        <a-textarea
          v-model="form.address"
          :maxLength="50"
          :rows="4"
          :placeholder="form.districtCode ? '请输入详细地址' : '请先选择省市区'"
          @blur="handleAddressBlur"
        ></a-textarea>
      </a-form-model-item>

      <a-form-model-item prop="description" label="项目描述">
        <a-textarea :rows="4" :maxLength="200" v-model="form.description"></a-textarea>
      </a-form-model-item>

      <a-form-model-item prop="buildingCount" label="楼栋数量">
        <a-input-number
          class="num_input"
          :disabled="diableBaseInfo"
          v-model="form.buildingCount"
          :precision="0"
        ></a-input-number>
      </a-form-model-item>

      <a-form-model-item prop="radarCount" label="雷达检测点数量">
        <a-input-number
          class="num_input"
          :disabled="diableBaseInfo"
          v-model="form.radarCount"
          :precision="0"
        ></a-input-number>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import DEFAULT_FORM_LAYOUT from '@/config/formLayout'
import ApiController from '@/views/project/api'
import { orgNameRule } from '@/utils/rules'
const NotEmptyRule = { required: true, trigger: 'blur', message: '请完善该项内容' }

const FORM = () => ({
  name: '',
  districtCode: '',
  address: '',
  description: '',
  buildingCount: '',
  radarCount: ''
})

const maxNumberRule = () => [
  NotEmptyRule,
  { type: 'number', min: 0, max: 999999, message: '数量范围0 - 999999, 请检查' }
]

export default {
  name: '',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const validateAddress = (rule, value, callback) => {
      if (!this.form.address || !this.form.districtCode) {
        callback(new Error('请完善项目地址'))
      } else {
        callback()
      }
    }

    return {
      show: false,
      loading: false,
      formLayout: DEFAULT_FORM_LAYOUT,
      form: FORM(),
      rules: {
        name: orgNameRule,
        address: [
          { required: true, trigger: 'blur', message: '请完善项目地址' },
          { validator: validateAddress, trigger: 'blur' }
        ],
        // description: [NotEmptyRule],
        buildingCount: maxNumberRule(),
        radarCount: maxNumberRule()
      }
    }
  },
  computed: {
    diableBaseInfo() {
      return this.item.id && this.item.status !== 'LAUNCHING'
    }
  },
  methods: {
    // 显示事件
    async handleShow() {
      this.show = true
      if (this.item.id) {
        const data = await ApiController.getItem(this.item)
        this.form = { ...FORM(), ...data }
      } else {
        this.form = FORM()
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    // 提交表单
    async handleConfirm() {
      const params = {
        ...this.form,
        id: this.item.id
      }

      try {
        this.loading = true
        await this.$refs.form.validate()
        if (this.item.id) {
          await ApiController.edit(params)
        } else {
          await ApiController.add(params)
        }
        this.$message.success('操作成功!')
        this.$emit('successed')
        this.show = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    handleAddressBlur() {
      this.$refs.form.validateField('address')
    }
  }
}
</script>

<style lang="scss" scoped>
.offer_content {
  .unit {
    position: absolute;
    margin-left: 1em;
  }
}

.num_input {
  width: 100%;
}
</style>
