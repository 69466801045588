<!--
 * @Description: 列表
-->
<template>
  <a-card class="table_area_wrapper">
    <!-- 表格加一层wrapper, 方便 height=auto 处理 -->

    <div class="mb-10 btn_group_wrapper">
      <a-button v-auth:ORGANIZATION_PROJECTS_ADD type="primary" @click="handleAddEditItem">新增项目</a-button>
    </div>

    <div class="custom_vxe_table_wrapper">
      <vxe-table height="auto" :data="tableData" :loading="loading">
        <vxe-column title="项目ID" field="projectCode" width="180px"></vxe-column>
        <vxe-column title="项目名称" field="name" width="160px"></vxe-column>
        <vxe-column title="项目地址" field="address" width="360px">
          <template #default="{row}">
            <span>{{ row.districtAddress }}{{ row.address }}</span>
          </template>
        </vxe-column>
        <vxe-column title="项目描述" field="description" width="360px"></vxe-column>
        <vxe-column title="楼栋数量" field="buildingCount" width="120px" align="center"></vxe-column>
        <vxe-column title="雷达检测点数量" field="radarCount" width="140px" align="center"></vxe-column>
        <vxe-column title="项目报价" field="offer" width="160px" align="right">
          <template #default="{row}">
            <div class="offer_wrapper">
              <span>{{ row.offer | transformBidValue }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column title="状态" field="statusName" width="120px"></vxe-column>
        <vxe-column title="创建时间" field="createTime" width="160px"></vxe-column>
        <vxe-column title="操作" align="right" field="operate" min-width="240px" fixed="right">
          <template #default="{row}">
            <!-- 全周期内可修改(可修改项有区别) -->
            <a-button v-auth:EDIT_PROJECTS type="link" @click="() => handleAddEditItem(row)">修改</a-button>

            <!-- 立项中操作按钮 -->
            <template v-if="showLauchBtns(row)">
              <a-button
                type="link"
                v-auth:ORGANIZATION_PROJECTS_DEL
                @click="() => handleDeleteItem(row)"
              >删除</a-button>
              <a-button
                type="link"
                v-auth:ORGANIZATION_PROJECTS_REQUEST_QUOTATION
                @click="() => handleSubmitBidRequest(row)"
              >请求报价</a-button>
            </template>

            <!-- 报价待确认的操作按钮 -->
            <template v-if="showConfirmBidBtns(row)">
              <a-button
                v-auth:ORGANIZATION_PROJECTS_CONFIRM_QUOTATION
                type="link"
                @click="() => handleAceptBid(row)"
              >确认报价</a-button>
              <a-button
                type="link"
                v-auth:ORGANIZATION_PROJECTS_REJECT_OFFER
                @click="() => handleRejectBid(row)"
              >驳回报价</a-button>
            </template>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <!-- 分页 -->
    <Pagination :pagination.sync="pagination" @change="fetchData" />

    <!-- 驳回 -->
    <RejectBidDialog ref="RejectBidDialog" @successed="fetchData"></RejectBidDialog>

    <!-- 新增/编辑项目 -->
    <AddEditItemDialog ref="AddEditItemDialog" :item="currentRow" @successed="fetchData"></AddEditItemDialog>
  </a-card>
</template>

<script>
import RejectReasonDialog from '../dialogs/RejectReasonDialog.vue'
import AddEditItemDialog from '../dialogs/AddEditItemDialog.vue'
import RejectBidDialog from '../dialogs/RejectBidDialog.vue'
import ApiController from '@/views/project/api'
import { getFilterParams } from '@/utils/utils.custom'

export default {
  components: {
    RejectReasonDialog,
    AddEditItemDialog,
    RejectBidDialog
  },
  props: {
    getFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      currentRow: {}
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // 拉取数据
    async fetchData() {
      const params = getFilterParams.call(this)

      try {
        this.loading = true
        const { data, total } = await ApiController.getList(params)
        this.pagination.total = total
        this.tableData = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    // 请求报价
    handleSubmitBidRequest(row) {
      const _requestAction = async () => {
        try {
          await ApiController.askOffer(row)
          this.fetchData()
        } catch (error) {
          console.log(error)
        }
      }

      this.$confirm({
        type: 'warning',
        title: '确认请求开始报价？',
        onOk: _requestAction
      })
    },
    // 添加/编辑项目及报价
    handleAddEditItem(row = {}) {
      this.currentRow = { ...row }
      this.$nextTick(() => {
        this.$refs.AddEditItemDialog.handleShow(row)
      })
    },
    // 显示修改，删除，请求报价按钮
    showLauchBtns(row) {
      return row.status === 'LAUNCHING' // 立项中显示
    },
    // 是否显示 确认报价和驳回报价 按钮
    showConfirmBidBtns(row) {
      // return ['OFFERED']
      return row.status === 'OFFERED' // 报价待确认
    },
    // 删除项目
    handleDeleteItem(row) {
      const _deleteAction = async () => {
        try {
          await ApiController.deleteItem(row.id)
          this.fetchData()
        } catch (error) {
          console.log(error)
        }
      }

      const title = (h) => {
        return (
          <div>
            <span>确定要删除么？</span>
            <b style="color: #C03639;">删除之后不可恢复!</b>
          </div>
        )
      }

      this.$confirm({
        type: 'warning',
        title: title,
        onOk: _deleteAction
      })
    },
    // 确认报价
    handleAceptBid(row) {
      const _action = async () => {
        const params = {
          id: row.id,
          confirm: true,
          rejectReason: ''
        }
        try {
          await ApiController.confirmOffer(params)
          this.fetchData()
        } catch (error) {
          console.log(error)
        }
      }

      this.$confirm({
        type: 'warning',
        title: '是否确认报价？',
        onOk: _action
      })
    },
    // 驳回报价
    handleRejectBid(row) {
      this.$refs.RejectBidDialog.handleShow(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn_group_wrapper {
  text-align: right;
  flex: 0 !important;
  display: block !important;
}

.offer_wrapper {
  text-align: right;
  padding-right: 1.5em;
}
</style>
