/*
 * @Description: 检测机构相关接口
 */
import { requestCustom as request } from '@/utils/request'

const BASE_URL = `/api/organization/project`

// 获取列表
function getList(params) {
  return request({
    url: `${BASE_URL}`,
    method: 'get',
    params
  })
}

// 添加
function add(data) {
  return request({
    url: `${BASE_URL}`,
    method: 'post',
    data
  })
}

// 编辑
function edit(data) {
  return request({
    url: `${BASE_URL}`,
    method: 'put',
    data
  })
}

// 获取详情
function getItem(data) {
  return request({
    url: `${BASE_URL}/${data.id}`,
    method: 'get'
  })
}

// 删除
function deleteItem(id) {
  return request({
    url: `${BASE_URL}/${id}`,
    method: 'delete'
  })
}

// 请求报价
function askOffer(data) {
  return request({
    url: `${BASE_URL}/${data.id}/askOffer`,
    method: 'post'
  })
}

// 确认/接受报价
function confirmOffer(data) {
  return request({
    url: `${BASE_URL}/${data.id}/confirmOffer`,
    method: 'post',
    data
  })
}

export default {
  getList,
  add,
  edit,
  deleteItem,
  getItem,
  askOffer,
  confirmOffer
}
