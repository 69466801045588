var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form_wrapper",
      attrs: {
        title: "新增修改项目",
        width: "700px",
        confirmLoading: _vm.loading,
      },
      on: { ok: _vm.handleConfirm },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
          "a-form-model",
          _vm.formLayout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { prop: "name", label: "项目名称" } },
            [
              _c("a-input", {
                attrs: { disabled: _vm.diableBaseInfo },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "address", label: "地址" } },
            [
              _c("DisctrictSelector", {
                attrs: {
                  placeholder: "请选择省市区",
                  maxLevel: 3,
                  allowClear: false,
                },
                model: {
                  value: _vm.form.districtCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "districtCode", $$v)
                  },
                  expression: "form.districtCode",
                },
              }),
              _c("a-textarea", {
                attrs: {
                  maxLength: 50,
                  rows: 4,
                  placeholder: _vm.form.districtCode
                    ? "请输入详细地址"
                    : "请先选择省市区",
                },
                on: { blur: _vm.handleAddressBlur },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "description", label: "项目描述" } },
            [
              _c("a-textarea", {
                attrs: { rows: 4, maxLength: 200 },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "buildingCount", label: "楼栋数量" } },
            [
              _c("a-input-number", {
                staticClass: "num_input",
                attrs: { disabled: _vm.diableBaseInfo, precision: 0 },
                model: {
                  value: _vm.form.buildingCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "buildingCount", $$v)
                  },
                  expression: "form.buildingCount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "radarCount", label: "雷达检测点数量" } },
            [
              _c("a-input-number", {
                staticClass: "num_input",
                attrs: { disabled: _vm.diableBaseInfo, precision: 0 },
                model: {
                  value: _vm.form.radarCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "radarCount", $$v)
                  },
                  expression: "form.radarCount",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }